import React from 'react'

import Layout from '../components/layout'
// import Team from '../components/team'
import Contact from '../components/contact'

const IndexPage = () => (
  <Layout>
    <div class="container">
      <h1>About</h1>
      <p>
        We are a full service development and design shop, that focuses on
        helping higher education institutions, non-profits, and niche brands
        cultivate engaging online experiences and communities. We help our
        clients reach their audience across all devices and platforms by
        providing a consistent brand experience. In addition to our development
        and design services, we offer accessibility audits, remediation, and
        training, as well as site support and maintenance.
      </p>
      <hr />
      <h2>Sean T. Walsh</h2>
      <p>
        <strong>
          <em>Co-Founder + Lead Developer/Designer</em>
        </strong>
      </p>
      <p>
        Sean has been designing and developing websites for over 10 years. He is
        an expert in working with various content management systems, but
        focuses primarily on Drupal and WordPress. He has several core
        contributions to Drupal 8 and also helps organize various events in the
        Drupal community. Since 2013 he has been a primary organizer for the
        Central NJ Drupal Meetup and DrupalCamp NJ, which hold their events on
        Princeton University’s campus.
      </p>
      <h2>Jessica L. Bladon</h2>
      <p>
        <strong>
          <em>Co-Founder + Account/Project Manager</em>
        </strong>
      </p>
      <p>
        Jessica is our project manager and also assists with design, content
        strategy, and testing for accessibility, browser support, and device
        functionality. She is experienced in both Drupal and WordPress and has
        been involved in the Drupal community attending and coordinating events
        such as 2014 & 2015 Drupal at the Jersey Shore core sprint and
        DrupalCamp NJ. She also attends local meet up groups such as Princeton
        A11Y to learn more about accessibility, and Jersey Shore Women in Tech
        as a way of keeping up with what’s happening in the tech community and
        supporting other women in technology.
      </p>
    </div>
    <Contact />
  </Layout>
)

export default IndexPage
